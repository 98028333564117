import React from 'react'
import {Link} from 'gatsby'
import Profile_Icon from '../components/profile_icon'
import css from '../styles/nav.module.scss'

export default class Navigation extends React.Component {

    constructor(props) {
        super(props);
    }

    onToggle() {
        const menu = document.getElementsByClassName("nav_menu")[0] as HTMLElement;
        // menu.classList.toggle("toggled");
        // height: padding of <a> + content's height
        const height = `calc(1em + ${menu.scrollHeight + 'px'})`;
        menu.style.maxHeight = 
            menu.style.maxHeight ? '' : height;
        
        menu.style.borderTop = 
            menu.style.borderTop ? 
            '' : '1px solid #eeeeee';

        const menuIcon = document
            .getElementsByClassName('toggleMenu')[0]
            .getElementsByTagName('span')[0] as HTMLElement;
        menuIcon.style.transform = 
            menuIcon.style.transform ? '' : 'rotateZ(-90deg)';

        const mask = document.getElementsByClassName('nav_mask')[0] as HTMLElement;
        mask.style.width =
            mask.style.width ? '' : document.documentElement.clientWidth + 'px';
        mask.style.height =
            mask.style.height ? '' : document.documentElement.clientHeight + 'px';
    }

    render() {
        const menu = (
            <div className="nav_menu">
                <ul>
                    <li key="blog">
                        <Link to="/blog/">Blog</Link>
                    </li>
                    <li key="github">
                        <a href="https://github.com/HarryL5004/">GitHub</a>
                    </li>
                </ul>
            </div>
        );

        return (
            <nav className={css.nav}>
                <div className={css.container}>
                    <div key="home" className={css.home}>
                        <Link to="/" className={css.homeLink}>
                            <div className={css.icon}>
                                <Profile_Icon />
                            </div>
                            <p>Harry Leung</p>
                        </Link>
                    </div>
                    <button onClick={() => this.onToggle()} className="toggleMenu">
                        <span className="material-icons">
                            &#xe5d2;
                        </span>
                    </button>
                </div>
                {menu}
                <div className="nav_mask" onClick={() => this.onToggle()}></div>
                <noscript>
                    {
                        <div className="nav_menu noscript">
                            <ul>
                                <li key="blog">
                                    <Link to="/blog/">Blog</Link>
                                </li>
                                <li key="github">
                                    <a href="https://github.com/HarryL5004/">GitHub</a>
                                </li>
                            </ul>
                        </div>
                    }
                </noscript>
            </nav>
        )
    }
}

{/* <nav className={css.nav}>
<ul className={css.container}>
    <li key="home" className={css.home}>
        <Link to="/" className={css.homeLink}>
            <div className={css.icon}>
                <Profile_Icon />
            </div>
            <p>Harry Leung</p>
        </Link>
    </li>
    <li key="blog">
        <Link to="/blog/">Blog</Link>
    </li>
    <li key="github">
        <a href="https://github.com/HarryL5004/">GitHub</a>
    </li>
</ul>
</nav> */}