import React from 'react'
import Footer from '../components/footer'
import Nav from '../components/navigation_bar'

// Layout component to control the overall CSS
export default function Layout({children}) {
    return (
        <div>
            <Nav />
            {children}
            <Footer />
        </div>
    )
}