import React from 'react'
import {Link} from 'gatsby'
import P5SortCanvas from './p5/p5wrap_sortCanvas'
import SourceBtn from './source_btn'
import css from '../styles/footer.module.scss'
import LILogo from '../../static/LinkedIn-Bug.png'
import TwitterLogo from '../../static/TwitterIconblue.svg'
import {Helmet} from 'react-helmet'
import firebase from "gatsby-plugin-firebase";

export default class Footer extends React.Component {
    state: {visible: boolean}
    // fAnalytics: firebase.analytics.Analytics;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }
    
    componentDidMount() {
        // this.fAnalytics = firebase.analytics();
    }

    logFooter() {
        // this.fAnalytics.logEvent('clicked_footer');
    }

    showExplanation(e) {
        // this.fAnalytics.logEvent('clicked_footer_whatsThis');
        this.setState({
            visible: !this.state.visible,
        });
    }

    logContractBtn(e) {
        // e.stopPropagation();
        // this.fAnalytics.logEvent('clicked_contract_btn');
    }

    render() {
        return (
            <div className={css.footer} id="footer" onClick={() => this.logFooter()}>
                <div className={css.info}>
                    <button className={css.whatsThis} onClick={(e) => this.showExplanation(e)}>
                        <span className="material-icons">
                            &#xe8fd;
                        </span>
                    </button>
                    <span className={css.explanation}>
                        <p>
                            {this.state.visible ? "A symmetrical visualization of the quicksort algorithm" : ""}
                        </p>
                        {this.state.visible ? 
                            <SourceBtn src="https://github.com/HarryL5004/Site_Canvas_Footer" color="light"
                                        style={{padding: '0.5em 1em'}}/> : ""
                        }
                    </span>
                    <noscript>
                        <span className={css.explanation}>
                            <p>
                                <b>Allow Javascript</b> for this page to see a symmetrical visualization of the quicksort algorithm.
                            </p>
                        </span>
                    </noscript>
                </div>
                <P5SortCanvas />
                <a href="#contacts" className={css.contactsButton} onClick={(e) => this.logContractBtn(e)}>
                    <span className="material-icons">
                        &#xe7fd;
                    </span>
                </a>
                <div className={css.contacts} id="contacts">
                    <p>Contacts</p>
                    <div className={css.socialMedia}>
                        <a href="https://www.linkedin.com/in/harry-leung-108575178">
                            <img src={LILogo} />
                        </a>
                        <a href="https://twitter.com/HarryTL_0">
                            <img src={TwitterLogo} />
                        </a>
                        <Helmet>
                            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet" />
                        </Helmet>
                        {/* <a href="mailto:mail@harrytleung.com" className={css.mailLink}>
                            <span className="material-icons">&#xE0bE;</span>
                        </a> */}
                    </div>
                </div>
                <p className={css.author}>© Harry Leung 2021</p>
            </div>
        )
    }
}