import React from 'react'
import css from '../styles/source_btn.module.scss'
import firebase from "gatsby-plugin-firebase";

type SourceBtn_proptypes = {name?: string, src: string, color: string, style?: object};
export default function SourceBtn({name="View Source", src, color, style={}}: SourceBtn_proptypes) {

    const modifier = color === "dark" ? css.dark : css.light;

    const logEvent = (name: string) => {
        // if (typeof window !== 'undefined')
        //     firebase.analytics().logEvent(name, {src_url: src});
    }

    const logHover = () => {
        logEvent('hovered_check_source');
    }
    const logTouch = () => {
        logEvent('touched_check_source');
    }
    const logFocus = () => {
        logEvent('focused_check_source');
    }

    return (
        <a href={src} className={`${css.source} ${modifier}`} style={style}
            onMouseOver={logHover}
            onTouchStart={logTouch}
            onFocus={logFocus}>
            {name}
        </a>
    )
}