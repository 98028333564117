import React from 'react'
import {Helmet} from 'react-helmet'
import {graphql, useStaticQuery} from 'gatsby'

type SEO_propTypes = {title?: string, desc?: string, keywords?: Array<string>}
function SEO({title, desc, keywords = []}: SEO_propTypes) {
    const result = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        keywords
                    }
                }
            }
        `
    )

    const siteMetadata = result.site.siteMetadata;
    const metaTitle = title || siteMetadata.title;
    const metaDesc = desc || siteMetadata.description;

    keywords = keywords.concat(siteMetadata.keywords);
    let metaKeywords: string = ""
    for (let word of keywords) {
        metaKeywords += word + ", ";
    }
    metaKeywords = metaKeywords.slice(0, metaKeywords.length - 2);

    return (
        <Helmet>
            <html lang="en"/>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDesc}/>
            <meta name="author" content={siteMetadata.author} />
            <meta name="keywords" content={metaKeywords} />
        </Helmet>
    )
}

export default SEO;