import * as p5 from 'p5';

export default function p5Sort(sketch) {
    // swaps two numbers in the array
    const swap = (arr: Array<number>, index1: number, index2: number): void => {
        if (index1 < 0 || index1 >= arr.length ||
            index2 < 0 || index2 >= arr.length)
            throw "Index out of range";
        let temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
        return;
    }

    // partition function in quicksort
    const partition = (arr: Array<number>, low: number, high: number, pivot: number): number => {
        let left = low;
        let right = high - 1;
        while (left <= right) {
            while(arr[left] < pivot) {
                left++;
            }
            while(arr[right] >= pivot) {
                right--;
            }
            if (left >= right)
                break
            if (arr[left] >= pivot && arr[right] < pivot) {
                swap(arr, left, right);
                left++;
                right--;
            }
        }
        swap(arr, left, high);
        return left;
    }

    let arr: Array<number> = [];
    let partitions: Array<Array<number>> = [];
    let canvas: p5.Graphics;
    let graphicsDimension: {x: number, y: number};
    let numRects; // width of container / rectSize
    let isSorted: boolean = false;
    
    // draw a rectangle for every number in the array
    const visualize = (arr: Array<number>): void => {
        let rectWidth = graphicsDimension.x/numRects;
        for (let i = 0; i < arr.length; i++) {
            canvas.rect(i*rectWidth, sketch.height, rectWidth, -arr[i], 0, 0, 5, 5);
        }
        return;
    }

    // accepts main canvas width, height
    const setupSecondCanvas = (width, height) => {
        // setup a separate canvas for the visualization
        graphicsDimension = {x: Math.ceil(width / 2), y: height};
        canvas = sketch.createGraphics(graphicsDimension.x, graphicsDimension.y);
        canvas.background("#373737");
        canvas.noStroke();
        canvas.fill("#fc7233");

        return sketch.createVector(graphicsDimension.x, graphicsDimension.y)
    }

    // generate a set number of rects with random heights
    const generateRandNum = ({x, y}) => {
        let rectSize = 10;
        numRects = x / rectSize;
        for (let i = 0; i < numRects; i++) {
            arr.push(sketch.random(y));
        }
        partitions.push([0, arr.length-1]);
    }

    // visualize the partitions and clear canvas (buffer)
    const visualizePartitions = () => {
        visualize(arr);
        sketch.image(canvas, 0, 0);

        // make it symmetrical
        sketch.scale(-1, 1);
        sketch.translate(-sketch.width, 0);
        sketch.image(canvas, 0, 0);
        canvas.clear();
    }

    // main method to start the sorting
    // Returns true if array is sorted, false otherwise
    const sort = (): boolean => {
        sketch.background("#373737");

        // one partition per frame
        let low = partitions[0][0];
        let high = partitions[0][1];
        let newPivot = partition(arr, low, high, arr[high]);
        if (low < newPivot - 1)
            partitions.push([low, newPivot - 1]);
        if (newPivot + 1 < high)
            partitions.push([newPivot + 1, high]);
        partitions.shift();

        if (partitions.length === 0)
            return true;

        return false;
    }

    // reset the sorting and canvas' size
    const reset = (footerElem = document.getElementById("footer")) => {
        isSorted = false;

        canvas.remove();
        arr = [];
        partitions = [];
  
        const canvasSizes = {width: footerElem.offsetWidth, height: footerElem.offsetHeight};
        const sndCanvasDim = setupSecondCanvas(canvasSizes.width, canvasSizes.height);
        generateRandNum(sndCanvasDim);
  
        sketch.resizeCanvas(canvasSizes.width, canvasSizes.height);
    }

    sketch.setup = () => {
        // setup main canvas
        const element = document.getElementById("footer");
        const canvasSizes = {width: element.offsetWidth, height: element.offsetHeight};
        let main_canvas = sketch.createCanvas(canvasSizes.width, canvasSizes.height);
        main_canvas.id('footer_canvas');

        // setup a separate canvas for the visualization
        const canvasDim = setupSecondCanvas(sketch.width, sketch.height);
        generateRandNum(canvasDim);
    }

    sketch.draw = () => {
        // let canvasDif = document.getElementById('footer_canvas').getBoundingClientRect();
        
        // if canvas not in viewport and array is sorted, reset the array but don't start sorting
        // if (canvasDif.y > sketch.height+300 && isSorted) {
        //     reset();
        //     visualizePartitions();
        // }

        // if canvas is close to viewport and array is NOT sorted, start sorting
        // if (canvasDif.y <= sketch.height+300 && !isSorted) { // 700
        //     isSorted = sort();
        //     visualizePartitions();
        // }

        if (!isSorted) {
            isSorted = sort();
            visualizePartitions();
        }
    }

    sketch.mouseClicked = () => {
        const element = document.getElementById("footer");
        if (sketch.mouseY < 0 || sketch.mouseY > element.offsetHeight || !isSorted)
          return;

        reset(element);
    }
}