import React from 'react'
import image from '../../static/self.png'
import css from '../styles/profile_icon.module.scss'

type Profile_IconProps = {width?: string, height?: string}
export default function Profile_Icon({width="40px", height="40px"}: Profile_IconProps) {
    return (
        <img alt="An image of myself" src={image} className={css.img}
            width={width} height={height} draggable="false"/>
    )
}