import React from 'react';
import {loadableP5 as P5Wrapper} from './loadable';
import P5Sort from './p5sort';

export default function P5SortCanvas(props) {
    return (
        <P5Wrapper sketch={P5Sort}>
            {props.children}
        </P5Wrapper>
    )
}